<template>
  <div>
    <div>
      <h1 class="mr-sm-4 header-tablepage">Gold Price</h1>
    </div>
    <div class="title-tabs mt-3">Gold Type List</div>
    <div class="no-gutters bg-white p-3">
      <div class="container px-4">
        <b-row class="mb-2">
          <b-col cols="1"> </b-col>
          <b-col cols="3"> Gold Type <span class="text-error">*</span></b-col>
          <b-col cols="3">
            Price / Bath <span class="text-error">*</span></b-col
          >
          <b-col cols="3">Price / Gram </b-col>
        </b-row>
        <b-row v-for="(item, index) in form.goldPriceList" :key="index">
          <b-col cols="1"> </b-col>
          <b-col cols="3">
            <InputText
              type="text"
              v-model="item.name"
              placeholder="Gold Type"
              :v="$v.form.goldPriceList.$each[index].name"
              :isValidate="$v.form.goldPriceList.$each[index].name.$error"
            ></InputText>
          </b-col>
          <b-col cols="3">
            <InputText
              type="text"
              @blur="handleBlurBath(index, 1)"
              @onKeypress="handleKeyPress"
              @input="(val, e) => formatCurrency(val, e, index)"
              v-model="item.formatPriceBaht"
              placeholder="0.00"
              :v="$v.form.goldPriceList.$each[index].formatPriceBaht"
              :isValidate="$v.form.goldPriceList.$each[index].formatPriceBaht.$error"
            ></InputText>
          </b-col>
          <b-col cols="3"
            ><InputText
              type="text"
              :disabled="true"
              v-model="item.formatPriceGram"
              placeholder="0.00"
            ></InputText>
          </b-col>
          <b-col cols="2">
            <font-awesome-icon
              v-if="index > 2"
              @click.prevent="deleteGoldPrice(index)"
              icon="trash-alt"
              title="Delete"
              class="main-color pointer mt-2"
            />
          </b-col>
        </b-row>
        <span class="text-link text-primary" @click="addGoldType">
          Add New Gold Type
        </span>
      </div>
    </div>
    <FooterAction routePath="/setting/gold-product" @submit="saveForm()" />
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      form: { goldPriceList: [] },
      filter: {
        page: 1,
        take: 999,
      },
      isLoading: false,
      divide: null,
    };
  },

  validations() {
    return {
      form: {
        goldPriceList: {
          $each: {
            formatPriceBaht: { required },
            name: { required },
          },
        },
      },
    };
  },

  created() {
    this.getList();
  },

  methods: {
    async getList() {
      this.$bus.$emit("showLoading");
      const res = await this.axios.post(`/setting/goldprice/list`, this.filter);
      await this.getDivideValue();
      this.form.goldPriceList = res.data.detail.data.map((x) => ({
        ...x,
        priceGram: (x.priceBaht / this.divide).toFixed(2),
        formatPriceBaht: this.formatPrice(x.priceBaht),
        formatPriceGram: this.formatPrice(
          (x.priceBaht / this.divide).toFixed(2)
        ),
      }));

      this.$bus.$emit("hideLoading");
    },
    async getDivideValue() {
      const res = await this.axios(`/setting/goldvariable/get-by-id/3`);
      this.divide = Number(res.data.detail.result.value);
    },
    formatPrice(value) {
      let formatVal = Number(value).toLocaleString("en-US", {
        maximumFractionDigits: 2,
      });
      return formatVal;
    },
    formatCurrency(val, e, index) {
      let value = val.replace(/[^0-9.]/g, "");
      const input = e.target;
      const cursorPos = input.selectionStart;
      const oldLength = input.value.length;

      if (!value) {
        this.form.goldPriceList[index].formatPriceBaht = "";
        this.form.goldPriceList[index].priceBaht = "";
        this.form.goldPriceList[index].formatPriceGram = "";
        return;
      }
      let numericValue = parseFloat(value);
      const formattedValue = this.formatPrice(numericValue);
      this.form.goldPriceList[index].priceBaht = numericValue;
      this.form.goldPriceList[index].priceGram =  (numericValue / this.divide).toFixed(2)
      this.form.goldPriceList[index].formatPriceBaht = formattedValue;
      this.form.goldPriceList[index].formatPriceGram = this.formatPrice(
        (numericValue / this.divide).toFixed(2)
      );

      this.$nextTick(() => {
        const newLength = input.value.length;
        const lengthDiff = newLength - oldLength;
        const newCursorPos = cursorPos + lengthDiff;
        input.setSelectionRange(newCursorPos, newCursorPos);
        let valueGram = this.form.goldPriceList[index].formatPriceGram;

        if (!valueGram.includes(".") && valueGram) {
          this.form.goldPriceList[index].formatPriceGram += ".00";
        } else {
          let parts = valueGram.split(".");
          if (parts[1].length === 1) {
            this.form.goldPriceList[index].formatPriceGram += "0";
          }
        }
      });
    },

    handleKeyPress(event) {
      const char = event.key;
      const value = event.target.value;
      const cursorPos = event.target.selectionStart;

      if (!/[\d.,]/.test(char)) {
        event.preventDefault();
      }
      if (
        (char === "." && value.includes(".")) ||
        (char === "," && value.includes(","))
      ) {
        event.preventDefault();
      }

      const decimalIndex = value.indexOf(".");
      if (decimalIndex !== -1) {
        const decimalPlaces = value.length - decimalIndex - 1;
        if (decimalPlaces >= 2 && cursorPos > decimalIndex) {
          event.preventDefault();
        }
      }
    },

    handleBlurBath(index) {
      let value = this.form.goldPriceList[index].formatPriceBaht;
      if (!value.includes(".") && value) {
        this.form.goldPriceList[index].formatPriceBaht += ".00";
      } else {
        let parts = value.split(".");
        if (parts[1].length === 1) {
          this.form.goldPriceList[index].formatPriceBaht += "0";
        }
      }
    },
    addGoldType() {
      this.form.goldPriceList.push({
        id: 0,
        name: "",
        priceBaht: 0.0,
        sortOrder: 1,
        createdBy: this.$cookies.get("back_office_admin_user_guid"),
        updatedBy: this.$cookies.get("back_office_admin_user_guid"),
        deleted: 0,
      });
    },
    async saveForm() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.$bus.$emit("showLoading");
      const res = await this.axios.post(
        `/setting/goldprice/update`,
        this.form.goldPriceList
      );
      if (res.data.result) {
        this.successAlert().then(() => {
          this.$router.push("/setting/gold-product");
        });
      } else {
        this.errorAlert(res.data.message);
      }
      this.$bus.$emit("hideLoading");
    },
    deleteGoldPrice(_index) {
      this.form.goldPriceList = this.form.goldPriceList.filter((x,index) => index !== _index)
    },
  },
};
</script>

<style></style>
